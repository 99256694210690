<!--
 * @Author: Neko
 * @Date: 2021-01-23 15:16:34
 * @LastEditTime: 2021-01-26 15:28:58
 * @LastEditors: Neko
-->
<template>
  <div>
    <div class="auth__page">
      <div class="auth__header">
        收银台
      </div>

      <div class="auth__logo">
        <img src="@/assets/images/logo.png">
      </div>

      <div class="auth__info">
        <p class="tips">商品总计</p>
        <p class="price">{{ price }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRef, watch, computed } from '@vue/composition-api'
import { Message } from 'element-ui'
import useWechatLogin from '@/composition/useWechatLogin.js'

import { useRequest } from '@/utils/request.js'

export default {
  setup(_, { root }) {
    const route = root.$route

    const { action } = useWechatLogin()

    const price = computed(() => route.query.price)

    const data = reactive({
      wechatCode: route.query.code,
      orderId: route.query.order_id,
      paymentId: route.query.payment_id,
      wechatOpenId: ''
    })

    const { fetch: getWechatOpenId } = useRequest('order/getWechatOpenId', {
      data: { code: data.wechatCode },
      initialData: toRef(data, 'wechatOpenId'),
      immediate: false
    })

    const { fetch: handleWechatPayFetch, data: handleWechatPayResult } = useRequest('order/handleWechatPayAction', {
      data: computed(() => ({
        orderId: data.orderId,
        paymentId: data.paymentId,
        openId: data.wechatOpenId
      })),
      immediate: false
    })

    // useAlipay(() => {
    //   // eslint-disable-next-line no-undef
    //   ap.getAuthCode({
    //     appId: '2021002125656778'
    //   }, res => {
    //     // eslint-disable-next-line no-undef
    //     ap.alert(JSON.stringify(res))
    //   })
    // })

    watch(() => data.wechatOpenId, res => {
      handleWechatPayFetch()
    })

    watch(() => handleWechatPayResult.value, res => {
      const result = JSON.parse(res.jsapi_pay_info)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          'appId': result.appId, // 公众号名称，由商户传入
          'timeStamp': result.timeStamp, // 时间戳，自1970年以来的秒数
          'nonceStr': result.nonceStr, // 随机串
          'package': result.package,
          'signType': result.signType, // 微信签名方式：
          'paySign': result.paySign // 微信签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            return Message.success('支付成功')
          }
        })
    })

    // 如果有 code 代表已经登录过了直接调用支付接口
    if (!route.query.code) {
      action()
    } else {
      getWechatOpenId()
    }

    return {
      price
    }
  }
}
</script>

<style lang="scss" scoped>

.auth__header {
  width: 100%;
  line-height: 44px;
  text-align: center;
  font-weight: bolder;
  color: #fff;
  background: #0049ac;
}

.auth__info {
  text-align: center;
}

.auth__info .tips {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  color: #666;
}

.auth__info .price {
  margin: 0;
  margin-bottom: 20px;
  font-size: 44px;
  font-weight: bolder;
  color: #F56C6C;
}

.auth__info .price::before {
  content: '¥';
  display: inline-block;
  margin-right: 5px;
}

.auth__logo {
  margin: 60px 0;
  text-align: center;
}
</style>
