/*
 * @Author: Neko
 * @Date: 2021-01-25 09:19:29
 * @LastEditTime: 2021-01-26 14:40:13
 * @LastEditors: Neko
 */

export default function useWechatLogin() {
  const action = function() {
    const appId = 'wxcec2f617e099800e'
    const redirectUri = `${window.location.host}/outter/pay${window.location.search}`
    const searchStr = encodeURIComponent(`${redirectUri}`)
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${process.env.VUE_APP_WECHAT_URL}&response_type=code&scope=snsapi_userinfo&state=${searchStr}#wechat_redirect`
  }

  return {
    action
  }
}
